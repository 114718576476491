import {
    handleQueryResolve,
    buildMapToField
} from '../utils';

export default function (patientID, GenericName) {

    if (patientID) {

        let attributes = [{
            param: 'PatientID',
            type: 'sql.Int',
            value: patientID
        }];
        let where = ['DBA.Prescription.PatientID = @PatientID'];


    if (GenericName) {
        attributes.push({
            param: 'GenericName',
            type: 'sql.VarChar(40)',
            value: '%' + GenericName + '%'
        });
        where.push('DBA.Drug.GenericName LIKE @GenericName');

    }

  

    return this.query(`
    SELECT DBA.Prescription.RxNumber as RxNumber,
    STRING(
        Drug.GenericName,
        '-',
        RTRIM (DBA.Drug.Strength)
    ) AS Medication,
    DBA.Prescription.Id as RxID,
    DBA.Prescription.PatientID,
    DBA.Drug.GenericName as GenericName,
    RTRIM (DBA.Drug.Strength) as RxStrength,
    DBA.Prescription.FillDate as RxDate,
    DBA.Prescription.FillQuantity as RxQtyDispense,
    CAST(DBA.Prescription.DaysSupply AS int) as RxDays,
    DBA.Prescription.IntervalDays as RxInterval
FROM DBA.Prescription
    INNER JOIN DBA.Drug ON DBA.Prescription.DrugId = DBA.Drug.Id
WHERE    ${ where.join(' AND ')} AND DBA.Prescription.FillDate > DATEADD(month, -12, GetDate()) AND
(
    (
        STRING(Drug.GenericName, '-', RTRIM (DBA.Drug.Strength)) IN 
        (
            'ROSUVASTATIN-40MG',
            'ROSUVASTATIN-20MG',
                                'ATORVASTATIN-80MG',
             'ATORVASTATIN-40MG',
            'FLUVASTATIN-40MG',
            'LOVASTATIN-40MG',
            'PRAVASTATIN-40MG',
            'SIMVASTATIN-80MG'
        )
    ) OR 
    Drug.GenericName LIKE '%ezetim%'
)
ORDER BY DBA.Prescription.FillDate DESC;
    `, attributes)
        .then(handleQueryResolve)
        .then((records) => {
            return records.map(row => buildMapToField(row));
        });
}
}

